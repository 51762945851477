import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { SizeMe } from 'react-sizeme';
import StackGrid from 'react-stack-grid';
import projectListStyles from './projectList.module.scss';

import cx from 'classnames';
import Img from 'gatsby-image';
import Category from '../Category/Category';
import PageFooter from '../pageFooter/pageFooter';

function BuildImage(image: any) {
  const imageEl = image.hero_image;
  return !!imageEl ? (
    <Img
      className={cx(projectListStyles.imageContainer)}
      fluid={imageEl.childImageSharp.fluid}
      alt={image.title}
    />
  ) : null;
}

const MdProjectList = ({list}: any) => {
  const [blogData, setBlogData] = useState(list);
  // console.log(blogData);

  useEffect(() => {
    setTimeout(() => {
      setBlogData([...blogData]);
    }, 400);
    return () => {};
  }, []);

  const renderSymbol = (type: string) => {
    return type === 'blog'
      ? '☱'
      : type === 'project'
      ? '☆'
      : type === 'graphic'
      ? '⛋'
      : '·';
  };

  function renderBlogData() {
    return (
      blogData.length > 0 && (
        <>
        <div className="pb-10">
          <SizeMe>
            {({size}) => {
              return (
                <StackGrid
                  appearDelay={100}
                  columnWidth={
                    size.width >= 960
                      ? '33%'
                      : size.width >= 640
                      ? '50%'
                      : '50%'
                  }
                  gutterWidth={size.width <= 640 ? 12 : 20}
                  gutterHeight={size.width <= 640 ? 12 : 20}>



                  {blogData
                    .filter((blog) => blog.node.frontmatter.title !== '')
                    .map((blog) => {
                      return (
                        <div
                          className={cx(
                            projectListStyles.post,
                            'font-charisma'
                          )}
                          key={blog.node.id}>
                          <Link
                            className="cursor-pointer"
                            to={`/${blog.node.fields.type}/${blog.node.fields.slug}`}>
                            <li
                              className={cx(projectListStyles.element)}
                              key={blog.node.fields.slug}>
                              {/* IMAGE */}
                              {blog.node?.fields.type !== 'blog' && (
                                <div className={projectListStyles.list__hero}>
                                  {BuildImage(blog.node.frontmatter)}
                                </div>
                              )}
                              <div className="md:px-4 md:py-3 py-3 px-3 relative">
                                {/* BOOKMARKED */}
                                {blog.node.frontmatter.featured && (
                                  <img
                                    className={cx(
                                      'absolute h-4 w-4',
                                      projectListStyles.bookmark
                                    )}
                                    src="/images/bookmark.svg"
                                  />
                                )}
                                {/* TITLE */}
                                <h2 className="text-sm pr-2 md:text-xl md:text-gray-800 text-gray-700 leading-snug">
                                  {blog.node.frontmatter.title}
                                </h2>

                                {/* CATEGORIES */}
                                <div className="hidden md:flex my-3">
                                  {blog.node.frontmatter.categories?.map(
                                    (cat: string) => {
                                      return (
                                        <>
                                          <Category
                                            tooltip
                                            key={cat}
                                            // text
                                            category={cat}
                                            isClickable={false}
                                            size={10}
                                          />
                                          {/* <Category isClickable={false} key={cat} size={8} category={cat}/> */}
                                        </>
                                      );
                                    }
                                  )}
                                </div>

                                {/* EXCERPT */}
                                <p className="hidden md:block md:pb-3 text-gray-600 tracking-wide">
                                  {blog.node.excerpt}
                                </p>

                                {/* DATE */}
                                <div
                                  className={cx(
                                    'opacity-75 text-gray-500 tracking-wide md:block md:item-center mr-1 mt-1'
                                  )}>
                                  <p className="text-3xs md:text-xs">
                                    {blog.node.frontmatter.date}
                                    <span
                                      className={
                                        'ml-2 text-2xs md:text-xs h-full text-gray-600'
                                      }>
                                      {renderSymbol(blog.node?.fields.type)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </li>
                          </Link>
                        </div>
                      );
                    })}
                </StackGrid>
              );
            }}
          </SizeMe>
        </div>
          <PageFooter />
        </>
      )
    );
  }

  return (
    <section>
      <div className={cx(projectListStyles.mainContainer)}>
        {
          // setTimeout(el => {
          renderBlogData()
          // }, 100)
        }
      </div>
    </section>
  );
};

export default MdProjectList;
