import { graphql, useStaticQuery } from 'gatsby';

export function useMdBaseData() {
    const data = useStaticQuery<GatsbyTypes.getAllMdDataQuery>(graphql`
        query getAllMdData {
            all: allMarkdownRemark(
                sort: { order: DESC, fields: frontmatter___date },
                limit: 2000,
                filter: {
                    fileAbsolutePath: {
                        regex: "/(posts|projects|graphics)/.*\\.md$/"
                    }
                }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "MMMM Do, YYYY")
                            author
                            title
                            featured
                            categories
                            hero_image {
                                childImageSharp {
                                    fluid( maxWidth: 800 ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        excerpt(pruneLength: 200)
                        fields {
                            slug
                            type
                        }
                    }
                }
            }
        }
    `);
  return data;
}
