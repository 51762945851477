import cx from 'classnames';
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import CatList from '../../components/CategoryList/CatList';
import MdProjectList from '../../components/ProjectsList/MdProjectsList';
import SEO from '../../components/seo';
import { usePageContext } from '../../contexts/pageContext';
import { useMdBaseData } from '../../static-queries/useBaseData';
import styles from './base.module.scss';

type IProjectsProps = {};

const Projects: React.FC<IProjectsProps> = ({}) => {
  const data = useMdBaseData();
  const [sortedData, setSortedData] = useState(null);
  const {setIsSideBarShown, isSideBarShown} = usePageContext();
  useEffect(() => {
    const allData = data.all.edges;

    const sorted = allData.sort((a, b) => {
      // Assume false if featured is undefined
      const featuredA = a.node.frontmatter.featured === true ? 1 : 0;
      const featuredB = b.node.frontmatter.featured === true ? 1 : 0;

      if (featuredA > featuredB) return -1;
      if (featuredA < featuredB) return 1;

      // Sort by date if both have the same featured flag
      return (
        new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)
      );
    });

    setSortedData(sorted);
  }, [data]);

  const [headerOpacity, setHeaderOpacity] = useState(1);

  const handleScroll = () => {
    const distanceFromTop = window.scrollY;
    const newOpacity = Math.max(1 - distanceFromTop / 60, 0);
    setHeaderOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setIsSideBarShown(false);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <div className={styles.background}></div>
      <article
        className={cx(
          'w-full px-6 pb-6 min-h-screen flex items-center flex-col',
          styles.mainContainer
        )}>
        <SEO title={'Gordo Labs'} />
        <div className={cx('hidden md:block w-full', !isSideBarShown && "pl-8")}>
          <CatList />
        </div>
        <section
          className={cx(
            styles.introSection,
            'flex items-center justify-center flex-col relative text-center overflow-hidden md:py-10 py-8 w-full mb-1'
          )}>
          <h2
            className="font-gordo md:flex hidden text-xl md:text-3xl tracking-wider rounded-2xl px-4 py-2  items-center gap-3"
            style={{opacity: headerOpacity}}>
            <span className="font-bold">☆</span>
            <span className="mb-1">☱</span>
            <span>⛋</span>
          </h2>
          {/*          <div className="font-charisma font-light mt-6 text-gray-600
          text-sm max-w-lg text-left md:text-center" dangerouslySetInnerHTML={{__html:blogMetaData.introSection.description}}/>*/}
        </section>

        <section className={styles.base}>
          {sortedData && <MdProjectList list={sortedData} />}
        </section>
      </article>
    </React.Fragment>
  );
};

Projects.defaultProps = {};

export default Projects;
